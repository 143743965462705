<template>
  <div>
    <h4 style="color: #fd3e5b" v-if="!completed">
      <span>
        To do!
      </span>
    </h4>
    <h4 v-if="completed">
      <span>
        <img src="images/dashboard/done.svg" alt="" />
      </span>
      Completed!
    </h4>
  </div>
</template>

<script>
import { reactive } from "vue"

export default {
  name: "OnboardingStep",
  props: {
    completed: Boolean,
  },
  setup(props) {
    return {}
  },
}
</script>
