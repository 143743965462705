<template>
  <div>
    <!-- Dashboard 1 Section -->
    <section id="dashboard1Tab" class="tabcontent dashboard1_wrapper">
      <div class="dashboard_title_area">
        <h2 class="dashboard_title1">Getting Started</h2>
        <h6 class="hide_section_text">Hide this section</h6>
      </div>

      <div class="dashboard_step_wrapper">
        <div class="step_itmes_area">
          <h6>Step 1</h6>
          <h2>Add a new profile</h2>
          <p>
            Your first step to using Credified is to create a profile.
            <br><br>
            You will need to add your personal details to the profile, this includes your Name, Address, as well as uploading proof of your identity.
          </p>
          <div class="step_footer">
            <a v-if="!store.state.onboarding.step1" href="/#/profiles"><h3>Create a profile</h3></a>
            <OnboardingStep :completed=store.state.onboarding.step1></OnboardingStep>
          </div>
        </div>
        <div class="step_itmes_area">
          <h6>Step 2</h6>
          <h2>Connect your credit report.</h2>
          <p>
            One of the first steps to most appropriately utilize the Credified software is to import a credit report, the supported companies are: <b>Initial Credit</b>, <b>IdentitiyIQ</b>, <b>SmartCredit</b>.
            <br><br>
            That way, Credified software can evaluate and analyze the uploaded imported credit report in order to identify potential negativities and deviations from the reporting standards for lawful and ethical reporting of a consumer's credit profile!
          </p>
          <div class="step_footer">
            <a v-if="!store.state.onboarding.step2" href="/#/profiles"><h3>Connect a credit monitoring company</h3></a>
            <OnboardingStep :completed=store.state.onboarding.step2></OnboardingStep>
          </div>
        </div>

        <div class="step_itmes_area">
          <h6>Step 3</h6>
          <h2>Purchase Generation(s)</h2>
          <p>            
            In order to generate a letter using credified's AI, you need to purchase a generation for each letter generated.
            <br><br>
            Each letter generated counts as one generation, you can view your previous purchases buy new generation(s) by clicking on the <b><a href="/#/payments">Payments</a></b> tab.
          </p>
          <div class="step_footer">
            <a v-if="!store.state.onboarding.step3" href="/#/payments"><h3>Purchase generation(s)</h3></a>
            <OnboardingStep :completed="store.state.onboarding.step3"></OnboardingStep>
          </div>
        </div>

        <div class="step_itmes_area">
          <h6>Step 4</h6>
          <h2>Create a Letter</h2>
          <p>
            Credified software's educational material and Compliance-focused credit enhancement smart wizard will help you create your first compliance-focused challenge and generate any needed attack enhancement letter(s) for you.
            <br><br>
            You will then print it out and mail it to the creditors, they will then be able to respond to your complaint within 30 days.
          </p>
          <div class="step_footer">
            <a href="/#/repair"><h3>Create a letter</h3></a>
            <OnboardingStep :completed="store.state.onboarding.step4"></OnboardingStep>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, reactive } from "vue"
import { useStore } from "vuex"
import { watch } from 'vue'

import OnboardingStep from '../components/OnboardingStep'

export default {
  name: "Home",
  components: {
    OnboardingStep
  },

  setup() {
    const store = useStore()

    return {
      store
    }
  },
}
</script>
